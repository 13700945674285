/**
 * Register search event for client
 */

import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { on, off, qwebApi } from '@/apis/qwebApi'
import { getSearchResultTransformer } from '@/transformers/transformerApi'
const Hook = () => {
  const navigate = useNavigate()
  const installedApps = useSelector((state) => state.app.installedApps)
  const countryCode = useSelector((state) => state.system.countryCode)
  const searchMutation = useMutation(['searchQueryClient'], (query) =>
    getSearchResultTransformer(query)
  )
  // Register client search event
  useEffect(() => {
    const onClientRealSearch = async (res) => {
      const apps = await searchMutation.mutateAsync(res.query)
      const searchApps = apps.slice(0, 12).map((app) => {
        return {
          ...app,
          isInstalled: installedApps.includes(app.package_name),
          isAppDetailAccessable: app.isAppDetailAccessable && countryCode !== 'JP',
        }
      })
      qwebApi({ event: 'pushRealSearchResult', data: searchApps })
    }
    on('clientRealSearch', onClientRealSearch)
    return () => off('clientRealSearch', onClientRealSearch)
  }, [searchMutation, installedApps, countryCode])
-
  // Notify to jump to search page by client
  useEffect(() => {
    const onClientGotoSearch = ({ query, $$from = 'search_bar' }) => {
      setTimeout(()=>{
        navigate({
          pathname: "/search",
          search: `query=${query}&from=${$$from}`,
        })
      },0)
    }
    on('clientGotoSearch', onClientGotoSearch)
    return () => {
      off('clientGotoSearch', onClientGotoSearch)
    }
  }, [navigate])
}

export default Hook
