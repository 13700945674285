import * as types from './constant'
import { fetchCloudGameApps, fetchJunsGameApps } from '../cloud/actions'
import {
  setCloudGameLoading,
  setGameCenterRotationLoading,
  setGameCenterHomeLoading,
  setCpiAppsForQuestLoading,
  setJunsGameLoading
} from '../loading/actions'
import {
  rotationDataTransformer,
  recommendedAppsTransformer,
  sidepanelDataTransformer,
  featureAppsDataTransformer,
  topicAppsDataTransformer,
  suggestedAppsTransformer,
} from '@/transformers'
import {
  getRotationAppsApi,
  getHomeAppsApi,
  getSidepanelAppsApi,
  getFeatureAppsApi,
  getSuggestedApps,
} from '@/apis/apps'

import { errorLog } from '@/tool'
import { switchLoader } from '../system/actions'

import { qwebApi } from '@/apis/qwebApi'

import logger from '@/tool/logger'
import { setData, getData } from '@/tool/storageFileToClient'

const showSkeleton = process.env['REACT_APP_SHOW_SKELETON']

// 更新本地安装游戏
export const setInstalledApps = (apps) => {
  return {
    type: types.SET_INSTALLED_APPS,
    payload: apps,
  }
}

// 更新本地安装pc游戏
export const setInstalledPCGames = (games) => {
  return {
    type: types.SET_INSTALLED_PC_GAMES,
    payload: games,
  }
}

// Fetch suggested apps action
export const fetchSuggestedApps = (countryCode) => {
  return async (dispatch) => {
    logger('start', 'fetchSuggestedApps')
    try {
      const _result = await getSuggestedApps()
      const suggestedApps = suggestedAppsTransformer(_result?.apps || [], countryCode)
      // Push suggested apps to client search bar
      qwebApi({
        event: 'pushPromotesApps',
        data: {
          apps: suggestedApps,
          default_search_term: _result?.default_search_term || '',
        },
      })
      dispatch({
        type: types.FETCH_SUGGESTED_APPS,
        payload: suggestedApps,
      })
    } catch (error) {
      errorLog(error)
    }
    logger('finish', 'fetchSuggestedApps')
  }
}

// Fetch appcenter/game_center_rotation action
export const setRotationApps = (apps) => {
  return {
    type: types.FETCH_ROTATION_APPS,
    payload: rotationDataTransformer(apps),
  }
}

// appcenter/game_center_rotation--轮播数据request
export const fetchRotationApps = () => {
  return async (dispatch) => {
    logger('start', 'fetchRotationApps')
    dispatch(setGameCenterRotationLoading(true))
    try {
      const _result = await getRotationAppsApi()
      setData('game_center_rotation', _result.rotation)
      dispatch(setRotationApps(_result.rotation))
    } catch (error) {
      errorLog(error)
    }
    logger('finish', 'fetchRotationApps')
    setTimeout(() => {
      dispatch(setGameCenterRotationLoading(false))
    }, 100)
  }
}

// Fetch appcenter/game_center_home action
export const setGameCenterApps = (apps) => {
  return {
    type: types.FETCH_GAME_CENTER_HOME_APPS,
    payload: recommendedAppsTransformer(apps),
  }
}

// appcenter/game_center_home--gameCenter数据request
export const fetchGameCenterApps = () => {
  return async (dispatch) => {
    logger('start', 'fetchGameCenterApps')
    dispatch(setGameCenterHomeLoading(true))
    try {
      const _result = await getHomeAppsApi()
      setData('game_center_home', _result)
      dispatch(setGameCenterApps(_result))
    } catch (error) {
      errorLog(error)
    }
    logger('finish', 'fetchGameCenterApps')
    setTimeout(() => {
      dispatch(setGameCenterHomeLoading(false))
    }, 100)
  }
}

// Shuffle recommended apps action
export const shuffleRecommendedApps = (apps) => {
  return {
    type: types.SHUFFLE_RECOMMENDED_APPS,
    payload: apps,
  }
}

// Fetch feature apps action
export const fetchFeaturedApps = () => {
  return async (dispatch) => {
    logger('start', 'fetchFeatureApps')
    dispatch(setCpiAppsForQuestLoading(true))
    try {
      const _result = await getFeatureAppsApi()
      dispatch({
        type: types.FETCH_FEATURED_APPS,
        payload: featureAppsDataTransformer(_result),
      })
    } catch (error) {
      errorLog(error)
    }
    logger('finish', 'fetchFeatureApps')
    setTimeout(() => {
      dispatch(setCpiAppsForQuestLoading(false))
    }, 100)
  }
}

// Set topic map action
export const setTopicApps = ({ data, topicId }) => {
  const apps = topicAppsDataTransformer(data.topic_game || [])
  return {
    type: types.SET_TOPIC_APPS,
    payload: { apps, topicId, info: data.topic },
  }
}

// Fetch side_panel action
export const fetchSidepanelApps = () => {
  return async (dispatch) => {
    logger('start', 'fetchSidepanelApps')
    try {
      const _result = await getSidepanelAppsApi()
      return dispatch({
        type: types.FETCH_SIDEPANEL_APPS,
        payload: sidepanelDataTransformer(_result.data),
      })
    } catch (error) {
      errorLog(error)
    }
    logger('finish', 'fetchSidepanelApps')
  }
}

// Initialize app action
export const initGameCenterApps = () => {
  return async (dispatch) => {
    logger('start', 'preCoreDataLoad')
    if (showSkeleton) {
      setTimeout(() => {
        qwebApi({ event: 'closeBootAnimation' })
      }, 100)
    }
    try {
      dispatch(fetchFeaturedApps())
      // dispatch(fetchSuggestedApps())
      const rotationData = await getData('game_center_rotation', [])
      const gameCenterData = await getData('game_center_home', [])
      if (rotationData.length) {
        dispatch(setGameCenterApps(gameCenterData))
        dispatch(setRotationApps(rotationData))
      }
      !showSkeleton && dispatch(switchLoader())
      Promise.all([dispatch(fetchRotationApps()), dispatch(fetchGameCenterApps())])
        .then(() => {
          logger('finish', 'preCoreDataLoad')
        })
        .finally(() => {
          !showSkeleton && dispatch(switchLoader(false))
        })
    } catch (error) {
    } finally {
      logger('finish', 'showWebPage')
      if (!showSkeleton) {
        setTimeout(() => {
          qwebApi({ event: 'closeBootAnimation' })
        }, 100)
      }
    }
  }
}

// Fetch cloud apps action
export const initCloudGamesApps = () => {
  return async (dispatch) => {
    dispatch(setCloudGameLoading(true))
    try {
      await dispatch(fetchCloudGameApps())
    } catch (error) {
      console.log('initCloudGamesApps:', error)
    }
    setTimeout(() => {
      dispatch(setCloudGameLoading(false))
    }, 100)
  }
}

// Fetch juns game apps action
export const initJunsGameApps = () => {
  return async (dispatch) => {
    dispatch(setJunsGameLoading(true))
    try {
      await dispatch(fetchJunsGameApps({}))
    } catch (error) {
      console.log('initJunsGameApps:', error)
    }
    setTimeout(() => {
      dispatch(setJunsGameLoading(false))
    }, 100)
  }
}

// Set local perference action
export const setLocalPerference = (perference) => {
  return {
    type: types.SET_LOCAL_PERFERENCE,
    payload: perference,
  }
}
